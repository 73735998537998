<template>
  <v-app style="min-height: 0%; margin-bottom: 0;">
    <div class="navbar navbar-fixed" :class="{ 'navbar-reduced': navbarReduced }"
      style="min-height: 0%; margin-bottom: 0;">
      <v-row>
        <v-col cols="12" sm="6" lg="12" class="row-center">
          <a href="/">
            <img src="../assets/Logo.png" alt="Logo" :class="{ 'logo-hidden': navbarReduced }" style="width: 300px;">
          </a>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script scoped>
export default {
  name: "App",
  data() {
    return {
      menuOpen: false,
      menuOpen1: false,
      menuOpen2: false,
      btn_clicked: false,
      navbarReduced: false,
      right: null,
    };
  },
  methods: {
    openMenu() {
      this.menuOpen = true;
    },
    openMenu1() {
      this.menuOpen1 = true;
    },
    openMenu2() {
      this.menuOpen2 = true;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    closeMenu1() {
      this.menuOpen1 = false;
    },
    closeMenu2() {
      this.menuOpen2 = false;
    },
    handleScroll() {
      this.navbarReduced = window.scrollY > 0;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
.v-application--wrap {
  min-height: auto !important;
}

.routerbar a {
  color: var(--bleu) !important;
}

.theme--light.v-application {
  background-color: var(--background) !important;
}

.navbar {
  border-bottom: solid 1px var(--bleu);
}

.navbar-fixed {
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  /* Choisissez la valeur de z-index en fonction de votre mise en page */
  background-color: var(--background);
  /* Choisissez la couleur de fond de votre barre de navigation */
  /* Ajoutez d'autres styles nécessaires à votre barre de navigation */
}

.routerbar {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 30px;
}

.routerbar a {
  font-family: roboto, medium;
  font-size: 18px;
  text-transform: uppercase;
  color: var(--bleu);
  text-decoration: solid;
}

/* Style pour le dropdown */
.v-list-item {
  cursor: pointer;
}

.v-list-item:hover {
  background-color: #f5f5f5;
}

.v-list-item__title {
  font-family: roboto, medium;
  font-size: 16px;
}

.v-menu__content {
  border-radius: 0;
  border: solid 1px var(--bleu);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  margin-top: 5px;

}

.v-menu__content .v-list {
  padding: 0;
}

.v-menu__content .v-list-item {
  min-height: 40px;
  padding: 5px 16px;
}



.btn-container {
  display: none;
}

.show-sidebar {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

@media (max-width: 1700px) {

  .routerbar a {
    font-size: 16px
  }
}

@media (max-width: 1264px) {


  .routerbar {
    margin-top: 0;
    padding: 0 2%;
  }

  .routerbar a {
    font-size: 14px
  }
}

@media (max-width: 767px) {
  .logo-hidden {
    display: none;
  }

  .navbar-reduced {
    height: 00px;
  }

  .routerbar {
    display: none;
  }


  .routerbar a {
    font-size: 14px
  }

  .btn-sidebar {
    border: solid 3px var(--bleu);
    font-family: Roboto, Regular;
    font-size: 17px;
    font-weight: 450;
    color: var(--bleu);
    background-color: white;
    padding: 8px 25px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    position: relative;
    text-decoration: none;
    border-radius: 25px;
  }

  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .theme--light v-icon {
    color: var(--bleu) !important;
  }
}

@import '~vuetify/dist/vuetify.min.css';
</style>